<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="scales-icon">
    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
    <path d="M7 20l10 0" />
    <path d="M6 6l6 -1l6 1" />
    <path d="M12 3l0 17" />
    <path d="M9 12l-3 -6l-3 6a3 3 0 0 0 6 0" />
    <path d="M21 12l-3 -6l-3 6a3 3 0 0 0 6 0" />
  </svg>
</template>

<script>
export default {
  props: {
    stroke: {
      type: String,
      default: '#8c8c8c',
    },
  },
}
</script>

<style lang="scss">
.scales-icon {
  outline: none;
  transition: stroke .3s ease;
  &:hover {
    cursor: pointer;
    stroke: #1890ff;
  }
}
</style>
