<template>
  <div class="sectors">
    <EmptyProvider :text="`<span>No sectors created yet.<br>Click 'Add' to set up your first one!</span>`">
      <a-table
        :dataSource="dataSource"
        :pagination="false"
        class="sectors-table"
        :class="{'sectors-table__edit-mode' : sectorEditingMode}"
        :row-key="(record) => record.id"
        :customRow="customRow"
        :columns="columns"
        :components="components"
      >
        <div slot="customTitle" class="d-flex align-items-center">
          <span class="d-flex mr-2">Chance</span>
          <a-popconfirm
            placement="left"
            title="Are you sure you want to set equal chances for all sectors?"
            @confirm="() => equalizeSectorsChances()"
          >
            <a-tooltip placement="topLeft">
              <template slot="title">
                <span>Equalize Chances</span>
              </template>
              <ScalesIcon v-if="!isActiveWheel && editPermission && dataSource.length && sectorEditingMode" style="max-width: 16px;" />
            </a-tooltip>
          </a-popconfirm>
        </div>
        <template slot="drag" slot-scope="text, record, index">
          <div class="d-flex align-items-center justify-content-center">
            <span v-if="isActiveWheel || !editPermission || dataSource.length <= 1 || !sectorEditingMode">{{ index + 1 }}</span>
            <a-icon v-else type="menu" class="drag-icon handle" />
          </div>
        </template>
        <template slot="image" slot-scope="image, record">
          <img v-if="record.image" :src="record.image" class="sectors__sector__image" :alt="record.name" />
          <img v-else src="/resources/images/default-image.svg" class="sectors__sector__image" alt="Default" />
        </template>
        <template slot="type" slot-scope="type">
          {{ getTypeName(type) }}
        </template>
        <template slot="name" slot-scope="name">
          {{ name ? name : '—' }}
        </template>
        <template slot="description" slot-scope="description">
          {{ description ? description : '—' }}
        </template>

        <template slot="chance" slot-scope="chance, record">
          <div class="change-weight d-flex align-items-center">
            <div v-if="editPermission">
              <a-input-number
                v-model="record.chance"
                :disabled="!sectorEditingMode || changeSectorLoading"
                type="number"
                :max="100"
                class="transparent-input"
                :class="sectorEditingMode ? 'transparent-input__focus' : 'transparent-input__hover-pointer'"
                :loading="changeSectorLoading"
                @focus="focusInputChance(record.id)"
                @blur="blurInputChance($event, record)"
              />
              <div
                v-if="sectorEditingMode && targetInputChanceId == record.id"
                class="d-flex"
                :class="maximumAvailableChance >= 0 ? 'text-default' : 'text-brown'"
                style="width: max-content; transition: color .5s ease;"
              >
                <span v-if="maximumAvailableChance > 0">Maximum chance available: {{ maximumAvailableChance }}</span>
                <span v-else>Maximum chances already set</span>
              </div>
            </div>

            <span v-else>{{ record.weight }}</span>
          </div>
        </template>

        <template slot="actions" slot-scope="text, record">
          <div class="sectors__sector__actions">
            <a-tooltip placement="topLeft">
              <template v-if="isActiveWheel" slot="title">
                <span>Stop the Wheel to edit</span>
              </template>
              <div
                v-if="editPermission && sectorEditingMode"
                class="custom-button-icon cursor-pointer"
                style="width: 40px; height: 40px;"
                @click="isActiveWheel ? () => {} : $router.push(`/fortune-wheel/${currentWheelId}/edit-sector/${record.id}`)"
              >
                <a-icon
                  class="font-size-18"
                  :class="isActiveWheel ? 'text-default cursor-default' : 'text-primary'"
                  type="edit"
                />
              </div>
            </a-tooltip>
          </div>
        </template>
      </a-table>
    </EmptyProvider>
  </div>
</template>

<script>
import apiClient from '@/services/axios'
import { getErrorMessage } from '@/views/apps/fortune-wheel/composables/useGetErrorMessage.js'
import EmptyProvider from '@/components/main/empty/emptyProvider'
import DraggableWrapper from '@/components/custom/draggableWrapper'
import ScalesIcon from '@/components/custom/icons/scalesIcon.vue'

const wheelApiUrl = process.env.VUE_APP_WHEEL_API_URL

export default {
  props: {
    currentWheelId: {
      type: Number,
    },
    dataSource: {
      type: Array,
      default: () => [],
    },
    sectorsSnapshot: {
      type: Array,
      default: () => [],
    },
    isActiveWheel: {
      type: Boolean,
      default: false,
    },
    sectorEditingMode: {
      type: Boolean,
      default: false,
    },
    changeSectorLoading: {
      type: Boolean,
      default: false,
    },
    editPermission: {
      type: Boolean,
      default: false,
    },
    deletePermission: {
      type: Boolean,
      default: false,
    },
  },
  provide() {
    return {
      data: this,
    }
  },
  components: {
    EmptyProvider,
    ScalesIcon,
  },
  data() {
    return {
      columns: [
        {
          title: '',
          dataIndex: 'drag',
          scopedSlots: { customRender: 'drag' },
          width: '5%',
        },
        {
          title: 'Sector image',
          dataIndex: 'image',
          scopedSlots: { customRender: 'image' },
          width: '15%',
        },
        {
          title: 'Sector type',
          dataIndex: 'type',
          scopedSlots: { customRender: 'type' },
          width: '15%',
        },
        {
          title: 'Sector name',
          dataIndex: 'name',
          scopedSlots: { customRender: 'name' },
          width: '20%',
        },
        {
          title: 'Description',
          dataIndex: 'description',
          scopedSlots: { customRender: 'description' },
        },
        {
          dataIndex: 'chance',
          slots: { title: 'customTitle' },
          scopedSlots: { customRender: 'chance' },
          width: '210px',
        },
        {
          title: '',
          dataIndex: 'actions',
          align: 'right',
          width: '60px',
          scopedSlots: { customRender: 'actions' },
        },
      ],
      components: {
        body: {
          wrapper: DraggableWrapper,
        },
      },
      currentSnapshotSectorPositions: [],
      targetInputChanceId: null,
    }
  },
  computed: {
    availableSectorChance() {
      return this.maximumAvailableChance >= 0
    },
    maximumAvailableChance() {
      const sumChances = this.dataSource.reduce((sum, sector) => {
        return sum + sector.chance
      }, 0)
      const currentChancesValue = 100 - sumChances
      if (currentChancesValue >= 0) {
        return currentChancesValue
      } else {
        return 0
      }
    },
  },
  methods: {
    customRow(sector) {
      return {
        on: {
          click: (e) => {
            if (this.sectorEditingMode || e.target.closest('.custom-button-icon')) {
              e.preventDefault()
              return false
            }
            this.$router.push(`/fortune-wheel/${this.currentWheelId}/view-sector/${sector.id}`)
          },
        },
      }
    },
    focusInputChance(id) {
      this.targetInputChanceId = id
    },
    blurInputChance(e, record) {
      this.$nextTick(() => {
        if (this.maximumAvailableChance) {
          record.chance = Number(e.target.value)
        } else {
          const test = this.dataSource.filter(el => el.id !== record.id)
          const availableChance = test.reduce((sum, sector) => {
            return sum + sector.chance
          }, 0)
          const chanceValue = 100 - availableChance
          if (chanceValue > 0) {
            record.chance = chanceValue
          } else {
            record.chance = 0
          }
        }
      })
      this.targetInputChanceId = null
    },
    getTypeName(type) {
      switch (type) {
        case 'generic':
          return 'Generic'
        case 'free_bet':
          return 'Freebet'
        case 'promo_code':
          return 'Promo code'
        case 're_spin':
          return 'Re-spin'
        default:
          return '—'
      }
    },
    setCurrentSnapshotSectorPositions(data = []) {
      this.currentSnapshotSectorPositions = data.map((sector, index) => {
        return { id: sector.id, position: index }
      })
    },
    async changeSectors() {
      const url = `${wheelApiUrl}/admin/wheels/${this.currentWheelId}/sectors/batch`
      const data = this.dataSource.map((sector, index) => {
        return { id: sector.id, position: index, chance: sector.chance }
      })
      try {
        this.loading = true
        const response = await apiClient.post(url, data)
        if (response?.data?.success) {
          this.$notification.success({
            message: response?.data?.message ? response.data.message : 'The wheel sectors have been successfully sorted and updated',
          })
          this.$emit('setSnapshot', this.dataSource)
          this.$emit('cancel', false)
        } else {
          getErrorMessage(response?.data)
        }
      } catch (e) {
        this.$notification.error({
          message: e?.message ? e.message : 'Error',
        })
      } finally {
        this.loading = false
      }
    },
    equalizeSectorsChances() {
      const numSectors = this.dataSource.length
      const maxChanceSum = 100
      const equalChance = Math.floor(maxChanceSum / numSectors)
      const remainder = maxChanceSum % numSectors

      // Уравниваем шанс секторов
      const data = this.dataSource.map((sector, index) => ({
        ...sector,
        chance: equalChance + (index === 0 ? remainder : 0), // Остаток добавляется только первому сектору
      }))

      this.$emit('equalizeSectors', data)
    },
  },
  mounted() {
    this.setCurrentSnapshotSectorPositions(this.dataSource)
    this.$root.$on('endDraggable', () => {
      this.setCurrentSnapshotSectorPositions(this.dataSource)
    })
  },
}
</script>

<style lang="scss">
.sectors {
  &__container {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  &__sector {
    display: flex;
    align-items: center;
    width: 100%;
    border: 1px solid #e4e9f0;
    border-radius: 4px;
    padding: 5px 20px;
    gap: 20px;
    &__image {
      height: 70px;
      border-radius: 2px;
    }
    &__name {
      flex: 1 1 auto;
    }
    &__actions {
      display: flex;
      justify-content: flex-end;
      gap: 10px;
    }
  }
}
.sectors-table {
  .ant-table-row {
    cursor: pointer;
  }
  &__edit-mode {
    .ant-table-row {
      cursor: default;
    }
  }
}
</style>
